import { useEffect } from 'react'
import type { FC, PropsWithChildren } from 'react'

import { Title } from '@web-solutions/core/ui-elements'

import { EditDetailsButton } from 'src/components/edit-details-button'

import { SuccessIcon } from './icons'

import classes from './style.module.scss'

interface SuccessOrderSectionProps extends PropsWithChildren {
  title: string,
  onEditClick: () => void
}

export const SuccessOrderSection: FC<SuccessOrderSectionProps> = ({ title, children, onEditClick }) => {
  useEffect(() => {
    window.scrollBy({
      top: -window.innerHeight,
      behavior: 'smooth'
    });
  }, []);

  return (
    <section className={classes.section}>
      <div className={classes.header}>
        <SuccessIcon className={classes.icon} />
        <Title className={classes.title}>
          {title}
        </Title>
        <EditDetailsButton onClick={onEditClick} />
      </div>
      {children}
    </section>
  )
}
