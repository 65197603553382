import { useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';

import Analytics from "@web-solutions/module-analytics";
import { PaymentSystem } from '@web-solutions/react-billing';

import {
  initOrders,
  createCustomer,
  handleErrorPurchase,
  handleSuccessPurchase,
  setIsSubmitByCard,
  subscribe,
  setPostCode,
} from '@web-solutions/core/store/billing/actions';

import {
  selectPaddleConfig,
  selectPostcode,
} from '@web-solutions/core/store/billing/selectors';

import { EVENT_ACTION } from "@web-solutions/core/constants/general";

import type { RecurlyError } from '@web-solutions/core/payment/components/threeDSecure';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';

import type { Subscription } from "@web-solutions/core/interfaces/billing";
import type { PaymentError } from "@web-solutions/core/interfaces/errors";

//@ts-ignore
import { processEmail } from 'src/store/profile/actions';

import type { PaymentProcessorProps } from "..";

import { PaymentFormOptions } from '../types';

interface Props extends Pick<PaymentProcessorProps, 'activeProduct' | 'orderDetails' | 'onSuccess' | 'onError' | 'onSubmit'> {
  paymentSystem: PaymentSystem,
  onCardSuccess?: () => void,
  onCardError?: (error: PaymentError) => void,
  analytics?: string
}

export const usePaymentProcessorBase = ({
  analytics = 'modal_payment',
  activeProduct,
  orderDetails,
  paymentSystem,
  onSubmit,
  onSuccess,
  onCardSuccess,
  onError,
  onCardError
}: Props) => {
  const dispatch = useDispatch<any>();

  const [paymentData, setPaymentData] = useState(null);
  const [tokenThreeDSecure, setTokenThreeDSecure] = useState(null);

  const paddleConfig = useSelector(selectPaddleConfig);
  const postcode = useSelector(selectPostcode);

  //@ts-ignore
  const email = useSelector((state) => state.profile.email);

  const {
    modeApplePay,
    modeGooglePay,
    cardFormLayout
  } = useRemoteConfig();

  const options: PaymentFormOptions = useMemo(() => ({
    orderDetails,
    config: Object.assign({ email, postcode }, paddleConfig),
    layout: cardFormLayout,
    applePayButtonParams: {
      color: 'black',
      enabled: !process.env.REACT_APP_APPLE_PAY_H2H,
      mode: modeApplePay,
      disabled: !email && paymentSystem !== PaymentSystem.SOLIDGATE && paymentSystem !== PaymentSystem.PADDLE,
      containerId: 'container-solidgate-apple-pay',
    },
    googlePayButtonParams: {
      color: 'black',
      type: 'plain',
      mode: modeGooglePay,
      disabled: !email && paymentSystem !== PaymentSystem.SOLIDGATE && paymentSystem !== PaymentSystem.PADDLE,
      containerId: 'container-solidgate-google-pay',
    },
    formParams: {
      autoFocus: false,
    },
  }), [
    orderDetails,
    paymentSystem,
    cardFormLayout,
    email,
    postcode,
    paddleConfig,
    modeApplePay,
    modeGooglePay
  ]);

  const handlePaymentSuccess = useCallback(async (purchase: Subscription) => {
    Analytics.trackEvent(analytics, EVENT_ACTION.SUCCESS);

    onSuccess(purchase);
  }, [
    analytics,
    onSuccess
  ]);

  const handleCardPaymentError = useCallback((error: PaymentError) => {
    Analytics.trackEvent(analytics, EVENT_ACTION.ERROR, { message: error?.message, paymentSystem: error?.paymentSystem });

    onCardError ? onCardError(error) : dispatch(handleErrorPurchase(error, { noToast: true }));

    onError && onError(error);
  }, [
    analytics,
    dispatch,
    onCardError,
    onError
  ]);


  const processFormData = useCallback(async (formData: any) => {
    Analytics.trackEvent(analytics, EVENT_ACTION.SUBMIT, {
      method: formData?.method,
      paymentSystem: formData?.paymentSystem,
      productId: activeProduct?.id,
      value: +activeProduct?.trialPriceAmount || +activeProduct?.amount,
      currency: activeProduct?.currency,
    });

    setPaymentData(formData);

    dispatch(subscribe(formData))
      //@ts-ignore
      .then((purchase: Subscription) => {
        handlePaymentSuccess(purchase);
      })
      .catch((error: PaymentError & { data?: any; }) => {
        if (error?.data?.three_d_secure_action_token_id) {
          setTokenThreeDSecure && setTokenThreeDSecure(error?.data?.three_d_secure_action_token_id);
        } else {
          handleCardPaymentError(error);
        }
      });
  }, [
    analytics,
    activeProduct?.amount,
    activeProduct?.currency,
    activeProduct?.id,
    activeProduct?.trialPriceAmount,
    dispatch,
    handleCardPaymentError,
    handlePaymentSuccess
  ]);

  const handleSubmit = useCallback(async (formData: any) => {
    const ps = formData.paymentSystem || paymentSystem;

    if (formData.email && formData.email !== email) {
      dispatch(processEmail(formData.email));
    }

    if (formData.email || formData.first_name || formData.last_name) {
      Analytics.trackEvent('user', 'info', {
        email: formData.email || undefined,
        first_name: formData.first_name || undefined,
        last_name: formData.last_name || undefined,
      });
    }

    if (ps === PaymentSystem.SOLIDGATE) {
      Analytics.trackEvent(analytics, EVENT_ACTION.SUBMIT, {
        method: formData?.method,
        paymentSystem: formData?.paymentSystem,
        productId: activeProduct?.id,
        value: +activeProduct?.trialPriceAmount || +activeProduct?.amount,
        currency: activeProduct?.currency,
      });
    }

    await dispatch(createCustomer({ email: formData.email, paymentSystem: ps }));

    if (ps !== PaymentSystem.SOLIDGATE) {
      await processFormData(formData);
    }

    onSubmit && onSubmit();
  }, [
    analytics,
    paymentSystem,
    email,
    activeProduct?.amount,
    activeProduct?.currency,
    activeProduct?.id,
    activeProduct?.trialPriceAmount,
    dispatch,
    onSubmit,
    processFormData
  ]);

  const handleCardFormSubmit = useCallback((formData: any) => {
    Analytics.trackEvent('card', EVENT_ACTION.SUBMIT);

    dispatch(setIsSubmitByCard(true));

    return handleSubmit(formData);
  }, [
    dispatch,
    handleSubmit
  ]);

  const handleCardPaymentSuccess = useCallback(async (purchase: Subscription) => {
    if (purchase.paymentSystem === PaymentSystem.PADDLE) {
      const em = purchase.email || email;

      dispatch(processEmail(em));
      // we do not handle purchase here because purchase is handled at success-url
    } else {
      if (!activeProduct.isOneTimePurchase) {
        dispatch(subscribe({ subscription_id: purchase.transaction_id }));
      }

      await dispatch(
        handleSuccessPurchase({
          ...purchase,
          isOneTimePurchase: activeProduct?.isOneTimePurchase,
          price_id: activeProduct?.id,
        }),
      );

      onCardSuccess && onCardSuccess();
      setTimeout(() => {
        handlePaymentSuccess(purchase);
      }, 1);
    }
  }, [
    email,
    activeProduct?.id,
    activeProduct?.isOneTimePurchase,
    dispatch,
    handlePaymentSuccess
  ]);

  const handle3DTokenExchanged = useCallback((actionToken: { id: any; }) => {
    setTokenThreeDSecure(null);

    dispatch(subscribe({ ...(paymentData || {}), three_d_secure_action_result_token_id: actionToken.id }))
      //@ts-ignore
      .then((purchase: Subscription) => {
        setPaymentData(null);

        handlePaymentSuccess(purchase);
      })
      .catch((error: { message: any; code: any; }) => {
        Analytics.trackEvent("ecommerce", EVENT_ACTION.ERROR, { message: error?.message || '3D Secure failed', code: error?.code });

        toast(error?.message || '3D Secure failed');
      });
  }, [
    paymentData,
    dispatch,
    handlePaymentSuccess
  ]);

  const handle3DTokenError = useCallback((error: RecurlyError) => {
    setTokenThreeDSecure(null);

    handleCardPaymentError({ message: error.message, code: error.code, paymentSystem: PaymentSystem.RECURLY });
  }, [handleCardPaymentError]);

  const handleUserInfoChange = useCallback((info: any) => {
    const em = info?.email;

    if (em && em !== email) {
      dispatch(processEmail(em));
      dispatch(initOrders());
    }

    if (info?.zip) {
      dispatch(setPostCode(info.zip));
    }

    if (info?.card_holder) {
      Analytics.trackEvent('user', 'info', { first_name: info.card_holder.split(' ')[0], last_name: info.card_holder.split(' ')[1], });
    }
  }, [
    email,
    dispatch
  ])

  return {
    formOptions: options,
    tokenThreeDSecure,
    processFormData,
    handleSubmit,
    handlePaymentSuccess,
    handleCardFormSubmit,
    handleCardPaymentSuccess,
    handleCardPaymentError,
    handle3DTokenExchanged,
    handle3DTokenError,
    handleUserInfoChange
  }
}