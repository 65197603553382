import type { FC, ComponentProps } from 'react'
import _map from 'lodash/map'

import { t } from '@web-solutions/module-localization'

import classes from './style.module.scss'

export const Footer: FC<ComponentProps<'div'>> = () => {
  //TODO: refactor after adding remoteConfig

  const links = {
    terms_of_service: 'terms_of_service',
    privacy_policy: 'privacy_policy',
    billing_terms: 'billing_terms',
    money_back: 'money_back',
    shipping: 'shipping',
    help_center: 'help_center',
  };

  return (
    <footer>
      <ul className={classes.footerWrapper}>
        {_map(links, (value, key) => (
          <li key={key}>
            <a href={value} className={classes.link}>
              {t(`footer.links.${key}`)}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  )
}
