import React from "react"

import { t } from "@web-solutions/module-localization";
import { Title } from "@web-solutions/core/ui-elements";

import { LandingButton } from "src/components/landing-button";

import classes from './style.module.scss';

const tKey = 'landing.surprise'

export const Surprise: React.FC = () => {

  return <div className={classes.wrapper}>
    <div className={classes.content}>
      <Title level='h1' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
    </div>
    <LandingButton className={classes.button} analyticCategory='landing_surprise' />
  </div>
}

export default Surprise;