
import type { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Astroline Shop';

export const PROJECT_SUPPORT_SUBJECT = '[Astroline Shop WEB]';

export const SUPPORT_EMAIL = '';

export const APP_LINKS: AppLinks = {
  WEB_APP_LINK: process.env.REACT_APP_WEB_APP_URL,
};