import {
  INITIAL_CONFIG as CORE_INITIAL_CONFIG,
  type RemoteConfig as CoreRemoteConfig,
  BaseOfferProductTypes
} from 'core/constants/remote-config';

import { SummarySection } from 'src/types/remote-config';

export type OfferProductId = 'birth_chart_hardcover'

export interface RemoteConfig extends CoreRemoteConfig {
  landingStack: Array<SummarySection>,
  presummaryStack: Array<SummarySection>,
  availableShippingCountries: string[],
  product: Omit<BaseOfferProductTypes<OfferProductId>, 'title' | 'subTitle'>
  shopMode: 'auto_add_to_cart' | 'manual_add_to_cart',
}

export const INITIAL_CONFIG: RemoteConfig = {
  ...CORE_INITIAL_CONFIG,
  landingStack: [],
  presummaryStack: [],
  availableShippingCountries: [],
  product: {
    notActivePrice: '',
    activePrice: '',
    id: 'birth_chart_hardcover',
    discount: '',
    price: 0,
    currency: "USD"
  },
  shopMode: 'auto_add_to_cart',
};
