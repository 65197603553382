import { useSelector } from 'react-redux';

import { T } from "@web-solutions/module-localization"

import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { selectCartProducts } from "src/store/cart/selectors";

import classes from '../../style.module.scss';
import { Info } from '../info';

interface Props {
  isPersonalized: boolean;
}

export const NoPrice: React.FC<Props> = ({ isPersonalized }) => {

  const { product } = useRemoteConfig();

  const cartProducts = useSelector(selectCartProducts)

  if (isPersonalized && !!cartProducts.length) {
    return <div>
      <p className={classes.title}>
        <T k={'landing.button.personalized_title'} />
      </p>
    </div>
  }

  if (isPersonalized) {
    return <Info activePrice={product.activePrice} notActivePrice={product.notActivePrice} />
  }

  return <div>
    <p className={classes.title}>
      <T k={'landing.button.birth_details'} />
    </p>
  </div>
}