import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { Title } from '@web-solutions/core/ui-elements'

import type { InfoListItem } from 'src/types/order'

import classes from './style.module.scss'

type InfoListBaseOwnProps = InfoListItem

type InfoListBaseProps = InfoListBaseOwnProps & Omit<ComponentProps<'div'>, keyof InfoListBaseOwnProps>

export const InfoListBase: FC<InfoListBaseProps> = ({ title, content, ...props }) => (
  <div {...props}>
    <Title level='h4' className={classes.title}>
      {title}
    </Title>
    <ul className={classes.list}>
      {content.map(({ text, decoration }, index) => Boolean(text) && (
        <li key={index} className={classNames(classes.item, classes[decoration ?? ''])}>
          {text}
        </li>
      ))}
    </ul>
  </div>
)