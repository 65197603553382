import type { FC } from 'react'
import classNames from 'classnames'

import ApplePay from '@web-solutions/core/payment/components/apple-pay'
import PaymentForm from '@web-solutions/core/payment/components/payment-form'
import { PayPalBtn } from '@web-solutions/core/payment/components/paypal'

import { RenderConfig, RenderComponents } from 'src/billing/payment-processor/types'

import { PaymentCardWrapper } from '../payment-card-wrapper'

import classes from './style.module.scss'

const RENDER_COMPONENTS: RenderComponents = {
  'apple-pay': ({ containerId, product, isButtonVisible, isContainerVisible, handleApplePayClick, handleApplePaySubmit }) => (
    <>
      {isButtonVisible && (
        <ApplePay
          className={classes.paymentButton}
          activeProduct={product}
          onApplePayClick={handleApplePayClick}
          onApplePaySubmit={handleApplePaySubmit}
        />
      )}
      <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !isContainerVisible })}>
        <div id={containerId} />
      </div>
    </>
  ),
  'google-pay': ({ containerId, isContainerVisible }) => (
    <div className={classNames(classes.buttonWrapper, { [classes.hidden]: !isContainerVisible })}>
      <div id={containerId} />
    </div>
  ),
  'pay-pal': ({ product, handlePayPalSubmit, handlePayPalSuccess, handlePayPalError }) => (
    <PayPalBtn
      style={{
        height: '50px'
      }}
      className={classes.paymentButton}
      product={product}
      onSubmit={handlePayPalSubmit}
      onSuccess={handlePayPalSuccess}
      onError={handlePayPalError}
    />
  ),
  'base-form': (props) => (
    <PaymentCardWrapper>
      <PaymentForm {...props} />
    </PaymentCardWrapper>
  )
}

interface PaymentMethodsProps {
  renderConfig: RenderConfig
}

export const PaymentMethods: FC<PaymentMethodsProps> = ({ renderConfig }) => (
  <div className={classes.paymentMethodsWrapper}>
    {renderConfig.map(({ name, condition, props }) => {
      const Component = RENDER_COMPONENTS[name]

      //@ts-ignore
      return condition && <Component key={name} {...props} />
    })}
  </div>
)
