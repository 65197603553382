import type { FC } from 'react'
import _map from 'lodash/map';

import { ICONS } from '@web-solutions/core/payment/base/body/icons/cards'

import classes from './style.module.scss'

export const CardsList: FC = () => (
  <ul className={classes.cardsListWrapper}>
    {_map(ICONS, (Icon, key) => (
      <li key={key} className={classes.item}>
        <Icon className={classes.icon} />
      </li>
    ))}
  </ul>
)
