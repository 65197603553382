import { useEffect, useImperativeHandle, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { useCreateOrdersProcessor } from '@web-solutions/core/one-time/hooks';

import { type PaymentProcessorProps, PaymentProcessor } from '../payment-processor';

export interface OneTimePaymentRef {
  createOrders: () => void;
}

export type OneTimePaymentProps = Omit<PaymentProcessorProps, 'orderDetails'>

export const OneTimePayment = forwardRef<OneTimePaymentRef, OneTimePaymentProps>(({
  activeProduct,
  trigger,
  orderMetadata,
  orderSolidMetadata,
  onError,
  onSuccess
}, ref) => {
  const email = useSelector((state: any) => state.profile.email);

  const {
    orderDetails,
    createOrders
  } = useCreateOrdersProcessor({
    activeProduct,
    oneTimeProducts: null,
    trigger: trigger ?? '',
    metadata: orderMetadata,
    solidMetadata: orderSolidMetadata
  });

  useEffect(() => {
    createOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct, email]);

  useImperativeHandle(ref, () => ({
    createOrders() {
      createOrders()
    }
  }));

  return (
    <PaymentProcessor
      activeProduct={activeProduct}
      orderDetails={orderDetails}
      onSuccess={onSuccess}
      onError={onError}
    />
  )
});
