import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { getShippingInformation } from 'src/store/profile/selectors';

import { getCountries } from 'src/api/shipping';

type Countries = { country_reference: string, note: string, require_state: number }[];

export const useFetchCountries = () => {
  const { availableShippingCountries } = useRemoteConfig();
  const formData = useSelector(getShippingInformation);
  const [countries, setCountries] = useState<{ value: string, label: string, state: boolean }[]>([
    {
      value: formData.country.value || '',
      label: formData.country.label || '',
      state: formData.country.state || false,
    }
  ]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        const countriesData = await getCountries() as any as Countries;
        const adaptedCountries = countriesData.map(country => ({
          value: country.country_reference,
          label: country.note,
          state: !!country.require_state
        }))
          .sort((a, b) => {
            if (a.value === 'US' && b.value !== 'US') return -1;
            if (a.value !== 'US' && b.value === 'US') return 1;
            if (a.label < b.label) return -1;
            if (a.label > b.label) return 1;
            return 0;
          });

        const filteredCountries = availableShippingCountries.length
          ? adaptedCountries.filter(country => availableShippingCountries.includes(country.value))
          : adaptedCountries

        setCountries([
          { value: '', label: '', state: false },
          ...filteredCountries.filter(country => country.value !== '')
        ]);

      } catch (error) {
        console.error("Error getting delivery countries:", error);
      }
    }

    fetchCountries();
  }, [availableShippingCountries]);
  return countries;
};
