import { useState, useEffect, useCallback, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Title } from '@web-solutions/core/ui-elements';

import { ROUTES } from 'src/constants/routes';

import { OneTimePayment, type OneTimePaymentRef } from 'src/billing/one-time-payment'

import { ErrorBlock } from '../error_block';

import { useGetPaymentData } from './hooks';

import classes from './style.module.scss'

const ANALYTICS = 'payment'

export const PaymentMethod: React.FC = () => {
  const navigateTo = useNavigate();

  const paymentRef = useRef<OneTimePaymentRef>(null);

  const { product, metadata } = useGetPaymentData();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    window.scrollBy({
      top: -window.innerHeight,
      behavior: 'smooth'
    });
  }, []);

  const handlePaymentError = useCallback((error: any) => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { product: product.id })

    paymentRef.current?.createOrders()

    if (error?.message) {
      setErrorMessage(t('common.payment_error', { error: error?.message }))
    }
  }, [product.id])

  const handlePaymentSuccess = useCallback(() => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUCCESS, { product: product.id })

    setErrorMessage('')

    navigateTo(
      {
        pathname: ROUTES.SUCCESS,
        search: window.location.search
      },
      {
        replace: true
      })
  }, [product.id, navigateTo])

  return (
    <div className={classes.paymentMethodWrapper}>
      <Title className={classes.title}>
        {t('payment_method.title')}
      </Title>
      <OneTimePayment
        ref={paymentRef}
        activeProduct={product}
        orderMetadata={metadata}
        onError={handlePaymentError}
        onSuccess={handlePaymentSuccess}
      />
      {
        Boolean(errorMessage) &&
        (
          <ErrorBlock className={classes.errorBlock} text={errorMessage} />
        )
      }
    </div>
  )
}