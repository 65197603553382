import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss'

interface Props {
  className?: string;
  show: boolean;
}

export const InvalidNote: React.FC<Props> = ({ className, show }) => (
  <p className={classNames(classes.error, className, {[classes.show]: show})}>{t('personalization.form.invalid_text')}</p>
)