

import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss';
import { FaqItem } from './components/item';

const tKey = 'landing.faq';

export const Faq = () => {

  const options = t(`${tKey}.options`, { returnObjects: true }) as unknown as { title: string; text: string; }[];

  return <div id='faq' className={classes.wrapper}>
    <div className={classes.title}>
      {t(`${tKey}.title`)}
    </div>
    <div className={classes.options}>
      {options.map((option, index) => {
        return <FaqItem key={index} title={option.title} text={option.text} />
      })}
    </div>
  </div>
}

export default Faq;