import { useState } from 'react';

import { ReactComponent as Arrow } from '../../icons/arrow.svg';
import { ReactComponent as Cross } from '../../icons/cross.svg';

import classes from '../../style.module.scss'

interface Props {
  title: string;
  text: string;
}

export const FaqItem: React.FC<Props> = ({ title, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => [
    setIsOpen(!isOpen)
  ]

  return <div className={classes.faq_item}>
    {isOpen ?
      <Cross onClick={handleClick} className={classes.faq_item_icon} />
      :
      <Arrow onClick={handleClick} className={classes.faq_item_icon} />
    }
    <div onClick={handleClick} className={classes.faq_item_title}>
      {title}
    </div>
    {isOpen && <div className={classes.faq_item_text}>
      {text}
    </div>}
  </div>
}