import { type BaseSyntheticEvent } from 'react';

import { Checkbox } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface Props {
  text: string | React.ReactNode;
  id: string;
  checked: boolean;
  onChange: (e: BaseSyntheticEvent) => void;
}

export const Agreement: React.FC<Props> = ({ text, checked, id, onChange }) => {

  return (
    <div className={classes.checkboxWrapper}>
      <Checkbox
        className={classes.checkboxInput}
        classNameCheckbox={classes.checkboxSpan}
        name={id}
        isChecked={checked}
        onChange={onChange}
      />
      <p className={classes.text}>{text}</p>
    </div>
  )
}