import React, { useEffect, useState, BaseSyntheticEvent } from 'react';
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';

import Mutator from '@magnus/react-native-mutator';

import classes from './style.module.scss';

interface Props {
  phoneNumber: string;
  autoCountry?: boolean;
  setPhoneNumber: (value: string) => void;
  containerClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  placeholder?: string;
  name?: string;
  onFocus?: (e: BaseSyntheticEvent) => void,
  onBlur?: (e: BaseSyntheticEvent) => void,
  onValidation?: (v: boolean) => void,
}

export const InputPhone: React.FC<Props> = ({
  setPhoneNumber,
  phoneNumber,
  autoCountry = true,
  containerClassName,
  inputClassName,
  buttonClassName,
  dropdownClassName,
  placeholder,
  name,
  onFocus,
  onBlur,
  onValidation,
}) => {
  const [countryCode, setCountryCode] = useState('');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    Mutator.getCountryCode()
      .then(data => {
        setCountryCode(data || '');
      })
      .catch((err) => {
        console.warn(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string) => {
    setPhoneNumber(value)
    if (value.length >= 10) {
      setShowError(false)
      if (onValidation) {
        onValidation(true)
      }
    } else {
      setShowError(true)
      if (onValidation) {
        onValidation(false)
      }
    }
  }

  return (
    <PhoneInput
      value={phoneNumber}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      containerClass={classNames(classes.container, containerClassName, { [classes.error]: showError })}
      inputClass={classNames(classes.input, inputClassName)}
      buttonClass={classNames(classes.flag_button, buttonClassName)}
      country={autoCountry ? countryCode?.toLowerCase() : undefined}
      dropdownClass={classNames(classes.dropdown, dropdownClassName)}
      placeholder={placeholder || '00-000-00-00'}
      inputProps={{
        name
      }}
    />
  )
}

