import { T } from "@web-solutions/module-localization"

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import classes from '../../style.module.scss';
import { Info } from '../info';

interface Props {
  isPersonalized: boolean;
}

export const Price: React.FC<Props> = ({ isPersonalized }) => {

  const { product } = useRemoteConfig();

  if (isPersonalized) {
    return <div>
      <p className={classes.title}>
        <T k={'landing.button.personalized_title'} />
      </p>
    </div>
  }

  return <Info activePrice={product.activePrice} notActivePrice={product.notActivePrice} />
}