import type { FC } from 'react'

import { t } from '@web-solutions/module-localization'

import { Title } from '@web-solutions/core/ui-elements'

import { NavHeaderBase } from 'src/components/order/nav-header-base'

import { baseTKey } from '../../constants'

export const NavHeader: FC = () => (
  <NavHeaderBase withBorder>
    <Title level='h4'>
      {t(`${baseTKey}.nav.title`)}
    </Title>
  </NavHeaderBase>
)