import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { t } from '@web-solutions/module-localization'

import { getShippingMethod } from 'src/store/profile/selectors';

import { useGetShippingDetailsContent } from 'src/hooks/order'

import type { InfoListItem } from 'src/types/order'

import { baseTKey } from '../../../constants'

const tKey = `${baseTKey}.main.details.section_titles`

export const useGetOrderDetailsData = (): InfoListItem[] => {
  const shippingMethod = useSelector(getShippingMethod);

  const orderDate = dayjs().format('MMMM D, YYYY');

  const shippingDetailsContent = useGetShippingDetailsContent({ withEmail: true });

  return [
    { title: t(`${tKey}.date`), content: [{ text: orderDate }] },
    shippingDetailsContent,
    { title: t(`${tKey}.shipping_method`), content: [{ text: shippingMethod.deliverier }, { text: shippingMethod.delivery_interval }] },
  ]
}