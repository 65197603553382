import classnames from 'classnames';

import { T } from "@web-solutions/module-localization"

import classes from '../../style.module.scss';

interface Props {
  activePrice: string;
  notActivePrice: string;
}

export const Info: React.FC<Props> = ({ activePrice, notActivePrice }) => {
  return <>
    <div>
      <p className={classes.title}>
        <T k={'landing.button.title'} />
      </p>
    </div>
    <div>
      <p className={classes.title}>
        {activePrice}
      </p>
      <p className={classnames(classes.not_active_price, classes.subtitle)}>
        {notActivePrice}
      </p>
    </div>
  </>
}