import { useMemo } from 'react'
import { useSelector } from 'react-redux';

import type {
  ProductDetails
} from '@web-solutions/core/store/billing/selectors';

import { getShippingMethod, } from 'src/store/profile/selectors';
import { selectPaymentMetadata } from 'src/store/billing/selectors';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

export const useGetPaymentData = () => {
  const product = useRemoteConfig().product;

  const { price: shippingPrice } = useSelector(getShippingMethod);
  const metadata = useSelector(selectPaymentMetadata);

  const currentProduct = useMemo(() => {
    const { id: productId, price: productPrice, currency: productCurrency } = product

    return {
      id: productId,
      amount: (Number(productPrice) || 0) + (Number(shippingPrice) || 0),
      currency: productCurrency,
      product: productId,
      isOneTimePurchase: true,
    }
  }, [
    product,
    shippingPrice
  ]) as unknown as ProductDetails

  return {
    product: currentProduct,
    metadata
  }
}