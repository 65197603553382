import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { init as initBilling, createCustomer } from '@web-solutions/core/store/billing/actions';
import { selectPaymentSystem } from '@web-solutions/core/store/billing/selectors';

import { processEmail } from 'src/store/profile/actions';

import { useAppDispatch } from 'src/store/hooks';

export const useGetInitBilling = () => {
  const dispatch = useAppDispatch();

  const paymentSystem = useSelector(selectPaymentSystem);

  const initBillingCallback = useCallback(async () => {
    await dispatch(initBilling({ isInitBillingOnly: true }));

    const res = await dispatch(createCustomer({ paymentSystem }))

    if (!!res?.email) {
      dispatch(processEmail(res.email));
    }
  }, [paymentSystem, dispatch])

  return initBillingCallback
}