import React, { BaseSyntheticEvent } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Input } from '@web-solutions/core/ui-elements';

import type { ShippingInformation } from 'src/store/profile';

import { InvalidNote } from '../invalid-note';

import classes from './style.module.scss';
interface Props {
  name?: string;
  type?: string;
  validation?: Record<string, boolean>;
  value?: string;
  children?: React.ReactNode;
  onChange?: (e: BaseSyntheticEvent<object, any, any>) => void;
  onFocus?: (e: BaseSyntheticEvent) => void;
  onBlur?: (e: BaseSyntheticEvent) => void;
  inside?: boolean;
  data?: ShippingInformation;
  tKey?: string;
}

export const InputWrapper: React.FC<Props> = ({
  name,
  validation,
  type,
  value,
  children,
  data,
  inside,
  tKey,
  onBlur,
  onFocus,
  onChange,
}) => {
  const notValid = validation && name && !validation?.[name]
  const isValidForInsideVariant = name && data?.[name as keyof ShippingInformation]

  return (
    <div className={classNames(classes.wrapper, { [classes.inside]: inside })}>
      {children ?
        children
        :
        <>
          <Input
            autoComplete="off"
            type={type}
            name={name}
            value={value}
            placeholder={t(`${tKey}.form.placeholders.${name}`)}
            className={classNames(classes.field, {
              [classes.error_border]: notValid,
              [classes.gap]: isValidForInsideVariant
            })}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
          />
          <label className={classNames(classes.label, { [classes.show]: isValidForInsideVariant })}>{t(`${tKey}.form.labels.${name}`)}</label>
        </>
      }
      {<InvalidNote className={inside ? classes.closer : classes.error} show={!!notValid} />}
    </div>
  );
};
