import { BirthLocationType, BirthDateType } from "../../types";

export type Validation = {
  name: boolean,
  last_name: boolean,
  birth_date: boolean,
  birth_time: boolean,
  location: boolean,
  email: boolean,
}

export const checkFormValidity = (validation: Validation, data: any) => {
  const isFormValid = Object.values(validation).every(value => value) &&
    Object.entries(data).every(([key, value]) => value !== '') &&
    checkIsValidBirthDate(data.birth_date)
  return isFormValid;
}

export const isValidNumber = (value: string): boolean => /^\d+$/.test(value);

export const checkIsValidBirthLocation = (data: BirthLocationType): boolean => {
  const hasAllFields = ['label', 'lat', 'lon'].every(field => field in data);

  if (!hasAllFields) {
    return false;
  }
  const { label, lat, lon } = data;
  return [label, lat, lon].every(value => value.length > 0);
};

export const checkIsValidBirthDate = (data: BirthDateType): boolean => {
  const { day, month, year } = data;
  return [day, month, year].every(isValidNumber) && [day, month, year].every(value => value.length > 0);
};