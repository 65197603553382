import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';
import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Button } from '@web-solutions/core/ui-elements';

import { setPersonalizedData } from 'src/store/profile/actions';

import { InputWrapper } from 'src/screens/components/input-wrapper';

import { prepareFormDataToSave } from 'src/utils'

import { BirthTimeType, BirthDateType, BirthLocationType } from '../../types';

import { useForm } from '../../hooks/use-form';

import { BirthDate } from './components/birth_date';

import { BirthTime } from './components/birth_time';

import Birthplace from './components/birthplace';

import { checkFormValidity } from './utils';

import classes from './style.module.scss';

const tKey = "personalization";

interface Props {
  onSubmit: () => void;
}

export const PersonalizedForm: React.FC<Props> = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const {
    validation,
    data,
    handleChange,
    setValidation,
    setData,
  } = useForm();

  const isFormValid = checkFormValidity(validation, data);

  useEffect(() => {
    Analytics.trackEvent('personalized_form', EVENT_ACTION.OPEN);
  }, []);

  const handleBirthDateChange = (newDate: BirthDateType) => {
    setData({ ...data, birth_date: newDate })
  };

  const handleBirthTimeChange = (newDate: BirthTimeType) => {
    setData({ ...data, birth_time: newDate })
  }
  const handlePlaceChange = (newDate: BirthLocationType) => {
    setData({ ...data, location: newDate })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Analytics.trackEvent('personalized_form', EVENT_ACTION.SUCCESS, { data });

    const { name, last_name, birth_date, birth_time, location, email } = prepareFormDataToSave(data);

    dispatch(setPersonalizedData({ name, last_name, birth_date, birth_time, location, email }));

    onSubmit();
  }

  const commonProps = {
    onChange: handleChange,
    tKey,
  }

  return (
    <div className={classes.wrapper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.field_wrap}>
          <InputWrapper
            name="name"
            type='text'
            value={data.name}
            validation={validation}
            {...commonProps}
          />
        </div>
        <div className={classes.field_wrap}>
          <InputWrapper
            name="last_name"
            type='text'
            value={data.last_name}
            validation={validation}
            {...commonProps}
          />
        </div>
        <div className={classes.field_wrap}>
          <label className={classes.top_label}>{t(`${tKey}.form.date_birth.title`)}</label>
          <InputWrapper name="birth_date" validation={validation} tKey={tKey}>
            <BirthDate
              onDateChange={handleBirthDateChange}
              onValidation={(value) => setValidation({ ...validation, birth_date: value })}
              birthDate={data.birth_date}
            />
          </InputWrapper>
        </div>
        <div className={classes.field_wrap}>
          <label className={classes.top_label}>{t(`${tKey}.form.time_birth.title`)}</label>
          <InputWrapper>
            <BirthTime
              onTimeChange={handleBirthTimeChange}
              birthTime={data.birth_time}
            />
          </InputWrapper>
        </div>

        <div className={classes.field_wrap}>
          <label htmlFor="location" className={classes.top_label}>{t(`${tKey}.form.labels.place`)}</label>
          <InputWrapper name="location" validation={validation}>
            <Birthplace
              onPlaceChange={handlePlaceChange}
              onValidation={(value) => setValidation({ ...validation, location: value })}
              location={data.location}
              className={classNames(classes.field, { [classes.error_border]: !validation.location })}
            />
          </InputWrapper>
        </div>
        <div className={classes.field_wrap}>
          <InputWrapper
            name="email"
            type='email'
            value={data.email}
            validation={validation}
            {...commonProps}
          />
        </div>
        <Button
          type='submit'
          className={classes.button}
          titleClassName={classes.button_title}
          disabled={!isFormValid}
          title={t('personalization.form.button')}
        />
      </form>
    </div>
  )
}