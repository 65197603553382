export enum SummarySection {
  BIRTH_CHART = 'birth_chart',
  BOOK_INFO = 'book_info',
  CONTACT_US = 'contact_us',
  BOOK_SPECIFICATIONS = 'book_specifications',
  DELIVERY_STEPS = 'delivery_steps',
  FAQ = 'faq',
  GREAT_INSIGHTS = 'great_insights',
  SHARE_JOY = 'share_joy',
  SURPRISE = 'surprise',
  WELCOME = 'welcome',
  WHAT_INSIDE = 'what_inside',
}