import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  loaded: boolean;
  pending: boolean;
  paymentProject?: string;
}

const initialState: AppState = {
  loaded: false,
  pending: false,
  paymentProject: process.env.REACT_APP_PAYMENT_PROJECT,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
  }
})

export const blacklist = ['loaded', 'pending'];

export { slice as appSlice };

