import type { FC } from 'react'

import Analytics from '@web-solutions/module-analytics'
import { t } from '@web-solutions/module-localization'

import { EVENT_ACTION } from '@web-solutions/core/constants/general'

import { Title } from '@web-solutions/core/ui-elements'

import { useShopNavigation } from 'src/hooks/use-shop-navigation'
import { ROUTES } from 'src/constants/routes'

import { ANALYTICS, baseTKey } from '../../constants'

import classes from './style.module.scss'

export const Header: FC = () => {
  const { navigateTo } = useShopNavigation();

  const handleCrossClick = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.CLOSE);

    navigateTo(ROUTES.MAIN)
  }

  return (
    <header className={classes.headerWrapper}>
      <Title level='h1' className={classes.title}>
        {t(`${baseTKey}.title`)}
      </Title>
      <button
        className={classes.crossButton}
        onClick={handleCrossClick}
      />
    </header>
  )
}
