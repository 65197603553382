
import { Button } from '@web-solutions/core/ui-elements';

import classes from './style.module.scss';

interface Props {
  title: string;
  disabled?: boolean;
  onCompleteButtonClick?: () => void;
}

export const CompleteButton: React.FC<Props> = ({ title, disabled, onCompleteButtonClick }) => (
  <Button
    type='submit'
    className={classes.button}
    disabled={disabled}
    title={title}
    onClick={onCompleteButtonClick}
  />
)