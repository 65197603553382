import { t, T } from '@web-solutions/module-localization';

import { LandingButton } from 'src/components/landing-button';

import classes from './style.module.scss';

const tKey = 'landing.delivery_steps'

export const DeliverySteps = () => {

  const steps = t(`${tKey}.steps`, { returnObjects: true }) as unknown as Array<Record<string, string>>;

  return <div className={classes.wrapper}>
    <div className={classes.title}>
      {t(`${tKey}.title`)}
    </div>
    <div className={classes.steps}>
      {steps.map(({ title, text }, index) => {
        return <div className={classes.step} key={index}>
          <div className={classes.step_number}>
            <T k={`${tKey}.step`} tOptions={{ step: index + 1 }} />
          </div>
          <div className={classes.step_content}>
            <div className={classes.step_title}>
              {title}
            </div>
            <div className={classes.step_description}>
              {text}
            </div>
          </div>
        </div>
      })}
    </div>
    <LandingButton analyticCategory='landing_delivery_steps' />
  </div>
}

export default DeliverySteps;