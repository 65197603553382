import type { FC, ComponentProps } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization'
import Analytics from '@web-solutions/module-analytics'

import { EVENT_ACTION } from 'src/constants/events'

import { ProductAndCustomerInfo } from 'src/components/product-and-customer-info'
import { EditDetailsButton } from 'src/components/edit-details-button'
import { PersonalizationPopUp } from 'src/screens/personalization'

import type { ShopProduct } from 'src/store/cart/types'

import { ProductPrices } from '../product-prices'

import classes from './style.module.scss'

interface ProductCardProps extends ComponentProps<'div'> {
  product: ShopProduct
}

export const ProductCard: FC<ProductCardProps> = ({ product, className, children, ...props }) => {
  const location = useLocation()

  const [isPersVisible, setIsPersVisible] = useState(false);

  const handleEditClick = () => {
    Analytics.trackEvent('edit_personalization', EVENT_ACTION.CLICK, { location: location.pathname, place: 'product_card' })

    setIsPersVisible(true)
  }

  const handleClose = () => setIsPersVisible(false);

  return (
    <>
      <div
        className={classNames(classes.productWrapper, className)}
        {...props}
      >
        <ProductAndCustomerInfo >
          <ProductPrices product={product} />
        </ProductAndCustomerInfo>
        <EditDetailsButton onClick={handleEditClick} />
        <p className={classes.shipText}>
          {t('common.ship_worldwide')}
        </p>
        {children}
      </div>
      <PersonalizationPopUp
        visible={isPersVisible}
        onSubmit={handleClose}
        onClose={handleClose}
      />
    </>

  )
}
