import request from 'core/utils/network';

const COUNTRIES = '/payments/shipping/countries';
const STATES = '/payments/shipping/states';
const SHIPPING_PRICES = '/payments/shipping/shipping-prices';

export async function getCountries() {
  return request.get(COUNTRIES);
}

export async function getStates(country: string) {
  return request.get(`${STATES}?country=${country}`);
}

export async function getShippingMethods(country: string, state?: string) {
  const params: Record<string, string | undefined> = {
    country,
    currency: 'USD',
    state 
  };

  return request.get(SHIPPING_PRICES, { params });
}