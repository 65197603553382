import type { FC } from 'react'

import { t } from '@web-solutions/module-localization'

import { Title } from '@web-solutions/core/ui-elements'

import { InfoListBase } from 'src/components/order/info-list-base'

import { baseTKey } from '../../constants'

import { useGetOrderDetailsData } from './hooks'

import classes from './style.module.scss'

const tKey = `${baseTKey}.main.details`

export const OrderDetails: FC = () => {
  const orderDetails = useGetOrderDetailsData();

  return (
    <div className={classes.orderDetailsWrapper}>
      <Title level='h3' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      {orderDetails.map((props, index) => <InfoListBase key={index} {...props} />)}
    </div>
  )
}
