import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PersonalizedData = {
  name: string,
  last_name: string,
  birth_date: {
    day: string,
    month: string,
    year: string,
  },
  birth_time: {
    hour: string,
    minutes: string
  },
  location: {
    label: string,
    lat: string,
    lon: string
  },
  email: string,
}

export type ShippingInformation = {
  email: string,
  name: string,
  last_name: string,
  country: {
    value: string,
    label: string,
    state: boolean,
  },
  state: {
    value: string,
    label: string,
  },
  city: string,
  street: string,
  apartment: string,
  zip_code: string,
  phone: string,
  checks: {
    personal: boolean,
    terms: boolean
  }
}

export type ShippingMethod = {
  id: string,
  deliverier: string,
  shipping_level: string,
  delivery_interval: string,
  price: string,
  currency: string,
}

export interface ProfileState {
  approximateLocation: {
    lat: string,
    lon: string,
    country_code: string,
    label: string,
  } | null;
  shippingInformation: ShippingInformation;
  shippingMethod: ShippingMethod
  personalizedData: PersonalizedData | null;
  email: string;
}

const initialState: ProfileState = {
  approximateLocation: null,
  shippingInformation: {
    email: '',
    name: '',
    last_name: '',
    country: {
      value: '',
      label: '',
      state: false,
    },
    state: {
      value: '',
      label: '',
    },
    city: '',
    street: '',
    apartment: '',
    zip_code: '',
    phone: '',
    checks: {
      personal: false,
      terms: false
    }
  },
  shippingMethod: {
    id: '',
    deliverier: '',
    shipping_level: '',
    delivery_interval: '',
    price: '',
    currency: ''
  },
  personalizedData: null,
  email: '',
};


const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload || initialState.email;
    },
    setApproximateLocation: (state, action: PayloadAction<{
      lat: string,
      lon: string,
      country_code: string,
      label: string,
    }>) => {
      state.approximateLocation = action.payload;
    },
    setPersonalizedData: (state, action: PayloadAction<PersonalizedData>) => {
      state.personalizedData = action.payload;
    },
    setShippingInformation: (state, action: PayloadAction<ShippingInformation>) => {
      state.shippingInformation = action.payload;
    },
    setShippingMethod: (state, action: PayloadAction<ShippingMethod>) => {
      state.shippingMethod = action.payload;
    },
    clearPersonalizedData: (state) => {
      state.personalizedData = null;
    }
  }
})

export const blacklist = [];

export { slice as profileSlice };
