import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string';

export const useShopNavigation = () => {
  const navigate = useNavigate();


  const navigateTo = useCallback((route: string) => {
    const params = queryString.parse(window.location.search);
    const search = queryString.stringify(params);

    navigate({
      pathname: route,
      search: search,
    })
  }, [navigate]);

  return {
    navigateTo,
  }
}
