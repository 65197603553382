import { DeliveryMethod } from '../diliverier';

import classes from './style.module.scss';

const skeletonCount = 3;

export const Skeleton = () => {
  return Array.from({ length: skeletonCount }, (_, index) => (
    <div key={index} className={classes.wrapper}>
      <DeliveryMethod
        deliverier='-- ---- --'
        delivery_interval='--- - ---'
        currency='-'
        price=' --'
      />
    </div>
  ));
}
