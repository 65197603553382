import type { FC } from 'react'

import { t } from '@web-solutions/module-localization'
import Analytics from '@web-solutions/module-analytics'

import { EVENT_ACTION } from '@web-solutions/core/constants/general'

import { InfoListBase } from 'src/components/order/info-list-base'

import { SuccessOrderSection } from '../success-order-section'

import { useGetShippingInfoContent } from './hooks'

interface SuccessShippingInformationProps {
  onEditClick: () => void
}

export const SuccessShippingInformation: FC<SuccessShippingInformationProps> = ({ onEditClick }) => {
  const shippingInfoContent = useGetShippingInfoContent()

  const handleEditClick = () => {
    Analytics.trackEvent('shipping_info_edit', EVENT_ACTION.CLICK);

    onEditClick();
  }

  return (
    <SuccessOrderSection
      title={t(`shipping_information.title`)}
      onEditClick={handleEditClick}
    >
      <>
        {shippingInfoContent.map((props, index) => <InfoListBase key={index} {...props} />)}
      </>
    </SuccessOrderSection>
  )
}

export default SuccessShippingInformation
