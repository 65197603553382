import { type ShippingInformation } from "src/store/profile";

export type Validation = {
  email: boolean,
  name: boolean,
  last_name: boolean,
  country: boolean,
  state: boolean,
  city: boolean,
  street: boolean,
  apartment: boolean,
  zip_code: boolean,
  phone: boolean,
}

type CheckData = {
  personal: boolean;
  terms: boolean;
};

type ValueType = string | { value: string; label: string; state?: boolean } | CheckData;

const isValueNotEmpty = (value: ValueType, key: string, isNeedState: boolean): boolean => {
  if (key === 'apartment') {
    return true
  }
  if (key === 'checks') {
    const checks = value as CheckData;
    return checks.personal && checks.terms;
  }
  if (typeof value === 'object' && value !== null) {
    if ('value' in value) {
      return key === 'state' && !isNeedState ? true : (value as { value: string }).value !== '';
    }
    return true;
  }
  return value !== '';
};

export const checkFormValidity = (
  validation: Validation,
  data: ShippingInformation,
  isNeedState: boolean,
): boolean => {
  const areValidationsPassed = Object.values(validation).every(Boolean);
  const areDataValuesValid = Object.entries(data).every(([key, value]) =>
    isValueNotEmpty(value, key, isNeedState)
  );

  return areValidationsPassed && areDataValuesValid;
};
