import DeviceProps from '@magnus/react-native-device-props';

import request from 'core/utils/network';

const authDeviceId = (idfm: string) =>
  request.post('/auth', { device_id: idfm, });

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm => authDeviceId(idfm))
