import { useState, useEffect } from 'react';

import { getStates } from 'src/api/shipping';

type States = { state_reference: string, note: string }[];


export const useFetchStates = (isNeedState: boolean, country: string, value: string, label: string) => {
  const [states, setStates] = useState<{ value: string, label: string }[]>([
    {
      value: value || '',
      label: label || '',
    }
  ]);

  useEffect(() => {
    async function getStatesData() {
      try {
        const states = await getStates(country) as any as States;
        const adaptedStates = states.map((state) => {
          return {
            value: state.state_reference,
            label: state.note,
          }
        })
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        })
        setStates([
          { value: '', label: '' },
          ...adaptedStates.filter(state => state.value !== '')
        ]);
      } catch (error) {
        console.error("Error getting delivery states:", error);
      }
    }
    if (isNeedState) {
      getStatesData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedState, country]);
  return states;
};
