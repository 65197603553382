import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Title } from '@web-solutions/core/ui-elements';

import { parseCurrencyString } from '@web-solutions/core/utils/parse-currency';

import { getCurrencySymbols } from '@web-solutions/react-billing/utils/prices';

import { getShippingMethod } from 'src/store/profile/selectors';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { NavHeaderBase } from 'src/components/order/nav-header-base';

import classes from './style.module.scss';

const tKey = 'order_checkout';
interface Props {
  isHide: boolean;
  onClick: () => void;
}

export const OrderCheckoutHeader: React.FC<Props> = ({ onClick, isHide }) => {
  const { activePrice, currency } = useRemoteConfig().product;
  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);
  const shippingPrice = parseCurrencyString(useSelector(getShippingMethod).price);
  const totalPrice = (parseCurrencyString(activePrice) + shippingPrice).toFixed(2);

  return (
    <NavHeaderBase withBorder={!isHide} className={classes.order_checkout}>
      <div className={classNames(classes.header, { [classes.hide]: isHide })}>
        <div className={classes.block}>
          <Title level='h4'>
            {t(`${tKey}.title`)}
          </Title>
          <button onClick={onClick}>
            {t(`${tKey}.${isHide ? 'hide' : 'show'}`)}
          </button>
        </div>
        <p className={classes.price}>{`${currencySymbol}${totalPrice}${currencySuffix}`}</p>
      </div>
    </NavHeaderBase>
  )
}