import { createSelector } from 'reselect';

import { getConvertedAddressData } from 'src/utils'

import { getShippingInformation, getShippingMethod, getPersonalizedData } from '../profile/selectors'

import type { PersonalizedData } from '../profile';

export const selectPaymentMetadata = createSelector(
  [
    getShippingInformation,
    getShippingMethod,
    getPersonalizedData
  ],
  (shippingInfo, shippingMethod, personolizedData) => {
    const { price: shippingPrice, shipping_level, deliverier, delivery_interval } = shippingMethod
    const { name: personalName, last_name: personalLastName, birth_date, birth_time, location } = (personolizedData || {}) as PersonalizedData
    const { name: shippingName, last_name: shippingLastName, country, state, zip_code, city, phone, apartment, street, email } = shippingInfo

    const { fullAddress } = getConvertedAddressData({ street, apartment })

    return {
      shipping_data: {
        personal_name: personalName,
        personal_last_name: personalLastName,
        birth_date,
        birth_time,
        location,
        shipping_name: shippingName,
        shipping_last_name: shippingLastName,
        email,
        country: country.label,
        state: state.label,
        postal_code: zip_code,
        city,
        address: fullAddress,
        phone,
        shipping_price: shippingPrice,
        shipping_level,
        shipping_option: deliverier,
        delivery_interval
      }
    }
  }
);