import type { FC, ComponentProps } from 'react'
import classNames from 'classnames';

import { LoaderIcon } from './icons'

import classes from './style.module.scss';

export const Loader: FC<ComponentProps<'div'>> = ({ className, ...props }) => (
  <div className={classNames(classes.wrapper, className)} {...props}>
    <LoaderIcon />
  </div >
)
