import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  onCheckClick: ((id: string) => void) | undefined;
  isActive: boolean;
  id: string;
}

const RoundCheckbox: React.FC<Props> = ({ isActive, onCheckClick, id }) => {
  const handleClick = () => {
    if (onCheckClick) {
      onCheckClick(id);
    }
  }
  return (
    <div
      className={classNames(classes.round_checkbox, { [classes.checked]: isActive })}
      onClick={handleClick}
    >
    </div>
  )
}

export default RoundCheckbox;