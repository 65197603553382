import type { FC } from 'react'

import { t } from '@web-solutions/module-localization'

import { Title } from '@web-solutions/core/ui-elements'

import { ProductAndCustomerInfo } from 'src/components/product-and-customer-info'
import { Footer } from 'src/components/order/footer'

import { baseTKey } from './constants'

import { NavHeader, OrderStatus, OrderDetails } from './components'

import classes from './style.module.scss'

export const OrderSuccess: FC = () => (
  <div className={classes.orderSuccessWrapper}>
    <NavHeader />
    <main className={classes.mainWrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${baseTKey}.main.title`)}
      </Title>
      <section className={classes.section}>
        <ProductAndCustomerInfo />
        <OrderStatus />
        <div className={classes.devider} />
        <OrderDetails />
      </section>
      <Footer />
    </main>
  </div>
)