import { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

//@ts-ignore
import { PlacePicker } from '@web-solutions/core/containers/place-picker';

import { BirthLocationType } from 'src/screens/personalization/types';

import { checkIsValidBirthLocation } from '../../utils';

import { ReactComponent as PinIcon } from './pin.svg';

import classes from './style.module.scss';

interface Props {
  onPlaceChange: (e: any) => void;
  onValidation: (v: boolean) => void,
  location: BirthLocationType,
  className: string,
}

const Birthplace: React.FC<Props> = ({ onPlaceChange, onValidation, location, className }) => {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    onValidation(!!checkIsValidBirthLocation(location))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleChange = (value: string) => {
    onPlaceChange(value);
  };

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  }, []);

  return (
    <div className={classes.birthplace}>
      <PinIcon className={classes.pin} />
      <PlacePicker
        placeholder={t('personalization.form.placeholders.place')}
        onChange={handleChange}
        value={location}
        inputClass={classNames(classes.picker, className)}
        className={classes.wrap}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        isFocused={isFocused}
      />
    </div>
  );
};

export default Birthplace;