import type { FC } from 'react'

import { Title } from '@web-solutions/core/ui-elements'

import { ShopProduct } from 'src/store/cart/types'

import classes from './style.module.scss'

interface Props {
  product: ShopProduct
}

export const ProductPrices: FC<Props> = ({ product }) => {
  const { activePrice, notActivePrice } = product;

  return (
    <div className={classes.productPricesWrapper}>
      <Title className={classes.activePrice}>
        {activePrice}
      </Title>
      <p className={classes.notActivePrice}>
        {notActivePrice}
      </p>
    </div>
  )
}
