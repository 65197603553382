import type { FC, PropsWithChildren } from 'react'

import { t } from '@web-solutions/module-localization'

import { Title } from '@web-solutions/core/ui-elements'

import { CardsList } from '../cards-list'

import classes from './style.module.scss'

export const PaymentCardWrapper: FC<PropsWithChildren> = ({ children }) => (
  <section className={classes.paymentCardWrapper}>
    <header className={classes.header}>
      <Title level='h4' className={classes.title}>
        {t(`payment_method.payment_card_title`)}
      </Title>
      <CardsList />
    </header>
    {children}
  </section>
)