export const INPUT_STYLES: Record<string, string> = {
  'border-radius': '6px',
  border: '1px solid #DEE2E6',
  background: '#FFF'
}

export const LABEL_STYLES: Record<string, string> = {
  'font-size': '12px',
  color: '1D2526',
  opacity: '0.6'
}

export const SUBMIT_STYLES: Record<string, string> = {
  height: '50px',
  'min-height': '50px'
}