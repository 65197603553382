type Params = {
  street: string,
  apartment?: string
}

export const getConvertedAddressData = ({ street, apartment }: Params) => {
  const fullAddress = `${street}${apartment ? `/${apartment}` : ''}`

  return {
    fullAddress
  }
}