import type { ImagesMap } from '@web-solutions/core/interfaces/images';

import BIRTH_CHART_HARDCOVER_1X from './birth_chart_hardcover@1x.webp';
import BIRTH_CHART_HARDCOVER_2X from './birth_chart_hardcover@2x.webp';
import BIRTH_CHART_HARDCOVER_3X from './birth_chart_hardcover@3x.webp';

export const IMAGES: ImagesMap = {
  BIRTH_CHART_HARDCOVER: {
    src: BIRTH_CHART_HARDCOVER_1X,
    srcSet: `${BIRTH_CHART_HARDCOVER_1X} 1x, ${BIRTH_CHART_HARDCOVER_2X} 2x, ${BIRTH_CHART_HARDCOVER_3X} 3x`,
  }
}