import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization'

import classes from './style.module.scss'

export const EditDetailsButton: FC<ComponentProps<'button'>> = ({ className, ...props }) => (
  <button
    className={classNames(classes.button, className)}
    {...props}
  >
    {t('common.edit_details')}
  </button>
)