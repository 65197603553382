import type { FC } from 'react'

import { t } from '@web-solutions/module-localization'

import classes from './style.module.scss'

interface Props {
  totalPrice: string
}

export const TotalPrice: FC<Props> = ({ totalPrice }) => {

  return (
    <div className={classes.totalPrice}>
      {t('common.badges.total')}
      <span className={classes.price}>
        {totalPrice}
      </span>
    </div>
  )
}
