import type { FC, PropsWithChildren, ComponentProps } from 'react'
import classNames from 'classnames'

import { ArrowIcon } from './icons'

import classes from './style.module.scss'

interface NavHeaderBaseProps extends PropsWithChildren<ComponentProps<'header'>> {
  withBorder?: boolean
}

export const NavHeaderBase: FC<NavHeaderBaseProps> = ({ withBorder = false, className, children, ...props }) => {
  //TODO: ADD nav logic

  return (
    <header className={classNames(classes.orderNavWrapper, className, { [classes.withBorder]: withBorder })} {...props}>
      <ArrowIcon className={classes.icon} />
      {children}
    </header>
  )
}