import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { T } from '@web-solutions/module-localization';

import { ReactComponent as WarningIcon } from './warning.svg';

import classes from './style.module.scss';

const tKey = 'shipping_method';

interface ErrorBlockProps extends ComponentProps<'div'> {
  text: string
  withHelpLink?: boolean
}

export const ErrorBlock: FC<ErrorBlockProps> = ({ className, text, withHelpLink, ...props }) => (
  <div className={classNames(classes.error_block, className)} {...props}>
    <div className={classes.left_block}>
      <WarningIcon />
      <p className={classes.text}>
        {text}
      </p>
    </div>
    {withHelpLink && (
      <T
        k={`${tKey}.help`}
        // eslint-disable-next-line
        components={{ help: <a href='url' className={classes.link} /> }} // TODO: подставить ссылку
      />
    )}
  </div>
)