import { useSelector } from 'react-redux';
import { useState, BaseSyntheticEvent } from 'react';

import { PersonalizedData } from 'src/store/profile';

import { getApproximateLocation, getPersonalizedData } from 'src/store/profile/selectors';

export const useForm = () => {
  const formData = useSelector(getPersonalizedData);
  const approximateLocation = useSelector(getApproximateLocation);
  const initialFormData = {
    name: '',
    last_name: '',
    birth_date: {
      day: '',
      month: '',
      year: '',
    },
    birth_time: {
      hour: '',
      minutes: ''
    },
    location: {
      label: approximateLocation?.label || '',
      lat: approximateLocation?.lat || '',
      lon: approximateLocation?.lon || '',
    },
    email: '',
  };

  const [data, setData] = useState<PersonalizedData>(formData || initialFormData);

  const [validation, setValidation] = useState({
    name: true,
    last_name: true,
    birth_date: true,
    birth_time: true,
    location: true,
    email: true,
  });

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;
    const trimValue = value.trim();
    let isValid = true;

    switch (name) {
      case 'name':
        setData({ ...data, name: value });
        isValid = trimValue.length >= 1 && trimValue.length <= 50;
        break;
      case 'last_name':
        setData({ ...data, last_name: value });
        isValid = trimValue.length >= 1 && trimValue.length <= 50;
        break;
      case 'email':
        setData({ ...data, email: value });
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        break;
    }
    setValidation({ ...validation, [name]: isValid });
  };

  return {
    handleChange,
    setValidation,
    setData,
    validation,
    data
  }
}
