import React, { type BaseSyntheticEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { t, T } from '@web-solutions/module-localization';

import { Select } from '@web-solutions/core/ui-elements';
import { InputPhone } from '@web-solutions/core/containers/phone-input';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from 'core/constants/general';

import { InputWrapper } from 'src/screens/components/input-wrapper';

import { setShippingInformation } from 'src/store/profile/actions';

import { prepareFormDataToSave } from 'src/utils';

import { CompleteButton } from '../complete_button';

import { useForm } from '../../hooks/use-form';

import { Agreement } from '../agreement';

import { checkFormValidity } from './utils';

import classes from './style.module.scss';
import 'react-phone-input-2/lib/style.css'

const tKey = 'shipping_information';

interface Props {
  onChangeStatus: () => void;
}

export const ShippingInformation: React.FC<Props> = ({ onChangeStatus }) => {
  const dispatch = useDispatch();
  const {
    validation,
    countries,
    states,
    data,
    isNeedState,
    handleChange,
    handleCheckChange,
    setValidation,
    setData,
  } = useForm();

  const [focusedFields, setFocusedFields] = useState({
    country: false,
    state: false,
    phone: false,
  });

  const isFormValid = checkFormValidity(validation, data, isNeedState);

  useEffect(() => {
    if (isFormValid) {
      Analytics.trackEvent('shipping_information_completed', EVENT_ACTION.SUCCESS);
    }
  }, [isFormValid]);

  const handleFocus = (e: BaseSyntheticEvent) => {
    const { name } = e.target;
    setFocusedFields({ ...focusedFields, [name]: true });
  }

  const handleBlur = (e: BaseSyntheticEvent) => {
    const { name } = e.target;
    setFocusedFields({ ...focusedFields, [name]: false });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    Analytics.trackEvent('shipping_information', EVENT_ACTION.SUBMIT, { data });

    const { email, name, last_name, country, state, city, street, apartment, zip_code, phone, checks } = prepareFormDataToSave(data);

    dispatch(setShippingInformation({ email, name, last_name, country, state, city, street, apartment, zip_code, phone, checks }));

    onChangeStatus();
  }

  const commonProps = {
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    inside: true,
    data,
    tKey,
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <p className={classes.title}>{t(`${tKey}.title`)}</p>
      <div className={classes.contact}>
        <p className={classes.group_title}>{t(`${tKey}.contact_title`)}</p>
        <div className={classes.field_wrap_long}>
          <InputWrapper
            name="email"
            type='email'
            value={data.email}
            validation={validation}
            {...commonProps}
          />
        </div>
      </div>
      <p className={classes.group_title}>{t(`${tKey}.details_title`)}</p>
      <div className={classes.short_fields_container}>
        <div className={classes.field_wrap_short}>
          <InputWrapper
            name="name"
            type='text'
            value={data.name}
            validation={validation}
            {...commonProps}
          />
        </div>
        <div className={classes.field_wrap_short}>
          <InputWrapper
            name="last_name"
            type='text'
            value={data.last_name}
            validation={validation}
            {...commonProps}
          />
        </div>
      </div>
      <div className={classes.field_wrap_long}>
        {(focusedFields.country || data.country.value) && <span className={classes.inside_label}>{t(`${tKey}.form.labels.country`)}</span>}
        <InputWrapper name="country" validation={validation} tKey={tKey}>
          <Select
            className={classNames(classes.field, { [classes.error_border]: !validation.country })}
            iconClassName={classes.arrow}
            name="country"
            options={countries}
            defaultValue={data.country.label}
            loading={!countries}
            {...commonProps}
          />
        </InputWrapper>
        <span className={classNames(classes.placeholder, { [classes.hidden]: focusedFields.country || data.country.value })}>
          {t(`${tKey}.form.placeholders.country`)}
        </span>
      </div>
      {isNeedState &&
        <div className={classes.field_wrap_long}>
          {(focusedFields.state || data.state.value) && <label htmlFor="state" className={classes.inside_label}>{t(`${tKey}.form.labels.state`)}</label>}
          <InputWrapper name="state" validation={validation} tKey={tKey}>
            <Select
              className={classNames(classes.field, { [classes.error_border]: !validation.state })}
              iconClassName={classes.arrow}
              name="state"
              options={states}
              defaultValue={data.state.label}
              loading={!states}
              {...commonProps}
            />
            <span className={classNames(classes.placeholder, { [classes.hidden]: focusedFields.state || data.state.value })}>
              {t(`${tKey}.form.placeholders.state`)}
            </span>
          </InputWrapper>
        </div>
      }
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="city"
          type='text'
          value={data.city}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="street"
          type='text'
          value={data.street}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="apartment"
          type='text'
          value={data.apartment}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        <InputWrapper
          name="zip_code"
          type='text'
          value={data.zip_code}
          validation={validation}
          {...commonProps}
        />
      </div>
      <div className={classes.field_wrap_long}>
        {(focusedFields.phone || data.phone) &&
          <label htmlFor="phone"
            className={classes.inside_label}>
            {t(`${tKey}.form.labels.phone`)}
          </label>
        }
        <InputWrapper name="phone" validation={validation} tKey={tKey}>
          <InputPhone
            phoneNumber={data.phone}
            autoCountry={false}
            containerClassName={classes.container}
            inputClassName={classNames(classes.input, {
              [classes.error_border]: !validation.phone,
              [classes.gap]: data.phone
            })}
            buttonClassName={classes.flag_button}
            placeholder={t(`${tKey}.form.placeholders.phone`)}
            name='phone'
            setPhoneNumber={(value) => setData({ ...data, phone: value })}
            onValidation={(value) => setValidation({ ...validation, phone: value })}
            {...commonProps}
          />
        </InputWrapper>
      </div>
      <p className={classes.footnote}>{t(`${tKey}.footnote`)}</p>
      <Agreement
        text={t(`${tKey}.personal_data_agreement`)}
        checked={data.checks.personal}
        id={'personal'}
        onChange={handleCheckChange}
      />
      <Agreement
        text={
          <T
            k={`${tKey}.terms_agreement`}
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            components={{ serviceTermsLink: <a href={'url'} className={classes.link} /> }} // TODO: подставить ссылку
          />
        }
        checked={data.checks.terms}
        id={'terms'}
        onChange={handleCheckChange}
      />
      <CompleteButton title={t(`${tKey}.form.button_text`)} disabled={!isFormValid} />
    </form >
  )
}