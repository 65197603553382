import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartState, ShopProduct } from './types';

const initialState: CartState = {
  products: [],
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setCartProducts: (state, action: PayloadAction<ShopProduct[]>) => {
      state.products = [...state.products, ...action.payload];
    },
    deleteProduct: (state, action: PayloadAction<ShopProduct>) => {
      state.products = state.products.filter((item) => item.id !== action.payload.id);
    },
  }
})

export const blacklist = [];

export { slice as cartSlice };
