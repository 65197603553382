import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization'

import { getShippingMethod } from 'src/store/profile/selectors';

import type { InfoListItem } from 'src/types/order'

export const useGetShippingMethodContent = (): InfoListItem[] => {
  const { deliverier, delivery_interval } = useSelector(getShippingMethod);

  return [
    { title: deliverier, content: [{ text: `${t('shipping_method.interval_prefix')} ${delivery_interval}` }] }
  ]
}