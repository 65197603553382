import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { getCurrencySymbols } from '@web-solutions/react-billing/utils/prices';

import RoundCheckbox from '../round-checkbox';

import classes from './style.module.scss';

interface Props {
  deliverier: string,
  delivery_interval: string,
  price: string,
  currency: string,
  isActive?: boolean,
  onMethodClick?: (id: string) => void,
  id?: string,
}

const tKey = "shipping_method";

export const DeliveryMethod: React.FC<Props> = ({ deliverier, delivery_interval, price, currency, isActive = false, onMethodClick, id = '' }) => {
  const { currencySuffix, currencySymbol } = getCurrencySymbols(currency);

  const handleClick = () => {
    if(onMethodClick) {
      onMethodClick(id);
    }
  }

  return (
    <div className={classNames(classes.method, { [classes.active]: isActive })} onClick={handleClick} id={id}>
      <div className={classes.left_block}>
        <RoundCheckbox isActive={isActive} onCheckClick={handleClick} id={id} />
        <div className={classes.dilivery_info}>
          <p className={classes.name}>{deliverier}</p>
          <p className={classes.interval}>{t(`${tKey}.interval_prefix`)}</p>
          <p className={classes.interval}>{delivery_interval}</p>
        </div>
      </div>
      <p className={classes.price}>{`${currencySymbol}${price}${currencySuffix}`}</p>
    </div>
  )
}