import { type BaseSyntheticEvent, useMemo } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Select } from 'core/ui-elements';

import { BirthTimeType } from 'src/screens/personalization/types';

import classes from './style.module.scss';

const tKey = "personalization.form.time_birth";

interface Props {
  onTimeChange: (e: any) => void;
  birthTime: BirthTimeType;
}

export const BirthTime: React.FC<Props> = ({ onTimeChange, birthTime }) => {
  const hoursOptions = useMemo(() => {
    return Array.from({ length: 24 }, (_, i) => ({ value: String(i + 1) }));
  }, []);

  const minutesOptions = useMemo(() => {
    return Array.from({ length: 60 }, (_, i) => ({ value: String(i + 1) }));
  }, []);

  const handleChange = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const data: BirthTimeType = {
      hour: '',
      minutes: '',
    };

    const formData = new FormData(e.currentTarget as HTMLFormElement);
    formData.forEach((value, key) => {
      if (key in data) {
        data[key as keyof BirthTimeType] = value as string;
      }
    });

    onTimeChange(data);
  };

  return (
    <div className={classes.birth_time}>
      <form className={classes.birth_time_selects} onChange={handleChange}>
        <Select
          defaultValue={birthTime.hour}
          name="hour" options={hoursOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthTime.hour })}
          disabledSelected={ t(`${tKey}.hour_placeholder`)}
        />
        <p className={classes.colon}>:</p>
        <Select
          defaultValue={birthTime.minutes}
          name="minutes"
          options={minutesOptions}
          classNameWrap={classNames(classes.select_wrap, { [classes.disabled]: !birthTime.minutes })}
          disabledSelected={ t(`${tKey}.minutes_placeholder`)}
        />
      </form>
    </div>
  )
}