import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/module-localization';

import { useRemoteConfig } from 'src/hooks/use-remote-config';
import { EVENT_ACTION } from 'src/constants/events';

import classes from './style.module.scss';

const tKey = 'landing.contact_us';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

export const ContactUs = () => {

  const {
    policyLinks,
    address,
  } = useRemoteConfig();

  const policyLinksLookup = {
    privacy_policy: policyLinks.privacy,
    terms: policyLinks.termsOfUse,
    billing_terms: policyLinks.billingTerms,
    money_back: policyLinks.moneyBack,
  };

  const handleLinkClick = (link?: string) => () => {
    Analytics.trackEvent('sub_main_payment_policy', EVENT_ACTION.CLICK, { link });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {t(`${tKey}.title`)}
      </div>
      <div className={classes.content}>
        <div className={classes.links}>
          <a href={`mailto:${SUPPORT_EMAIL}`} className={classes.link} target="__blank">
            {t(`${tKey}.contact_us`)}
          </a>
          {Object.entries(policyLinksLookup).map(([key, link]) => {
            return <a
              key={key}
              href={link}
              className={classes.link}
              onClick={handleLinkClick(link)}
            >
              {t(`${tKey}.${key}`)}
            </a>
          })}
        </div>
        <div className={classes.address}>
          {address}
        </div>
      </div>
      <div className={classes.all_rights}>
        {t(`${tKey}.all_rights_reserved`, { year: new Date().getFullYear() })}
      </div>
    </div >
  );

}

export default ContactUs;