import { useSelector } from 'react-redux';

import { t } from '@web-solutions/module-localization'

import { getShippingInformation } from 'src/store/profile/selectors';

import { useGetShippingDetailsContent } from "src/hooks/order"

import type { InfoListItem } from 'src/types/order'

export const useGetShippingInfoContent = (): InfoListItem[] => {
  const { email } = useSelector(getShippingInformation)

  const shippingDetailsContent = useGetShippingDetailsContent();

  return [
    { title: t('order.info_section_titles.contact_information'), content: [{ text: email }] },
    shippingDetailsContent
  ]
}