import { useState, BaseSyntheticEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import type { ShippingInformation } from 'src/store/profile';
import { getShippingInformation, getPersonalizedData } from 'src/store/profile/selectors';

import { useFetchCountries } from './use-fetch-countries';
import { useFetchStates } from './use-fetch-states';


export const useForm = () => {
  const email = useSelector(getPersonalizedData)?.email;
  const formData = useSelector(getShippingInformation);
  const initialFormData = { ...formData, email } as ShippingInformation;
  const [data, setData] = useState<ShippingInformation>(formData.email ? formData : initialFormData);
  const isNeedState = data.country.state;
  const countries = useFetchCountries();
  const states = useFetchStates(isNeedState, data.country.value, data.state.value, data.state.label);

  const [validation, setValidation] = useState({
    email: true,
    name: true,
    last_name: true,
    country: true,
    state: true,
    city: true,
    street: true,
    apartment: true,
    zip_code: true,
    phone: true,
  });

  useEffect(() => {
    if (!isNeedState) {
      setValidation({ ...validation, state: true });
      setData({ ...data, state: { value: '', label: '' } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNeedState])

  const handleChange = (e: BaseSyntheticEvent) => {
    const { name, value } = e.target;
    const trimValue = value.trim();
    let isValid = true;

    switch (name) {
      case 'email':
        setData({ ...data, email: value });
        isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        break;
      case 'name':
        setData({ ...data, name: value });
        isValid = trimValue.length >= 1;
        break;
      case 'last_name':
        setData({ ...data, last_name: value });
        isValid = trimValue.length >= 1;
        break;
      case 'country':
        setData({
          ...data,
          country: {
            value: value,
            label: countries.find(obj => obj.value === value)?.label!,
            state: countries.find(obj => obj.value === value)?.state!
          },
        });
        isValid = value.length >= 1;
        break;
      case 'state':
        setData({
          ...data,
          state: {
            value: value,
            label: states.find(obj => obj.value === value)?.label!
          },
        });
        isValid = value.length >= 1;
        break;
      case 'city':
        setData({ ...data, city: value });
        isValid = trimValue.length >= 1;
        break;
      case 'street':
        setData({ ...data, street: value });
        isValid = trimValue.length >= 1;
        break;
      case 'apartment':
        setData({ ...data, apartment: value });
        break;
      case 'zip_code':
        setData({ ...data, zip_code: value });
        isValid = trimValue.length >= 1;
        break;
      default:
        break;
    }
    setValidation({ ...validation, [name]: isValid });
  };

  const handleCheckChange = (e: BaseSyntheticEvent) => {
    const { name, checked } = e.target;
    setData(prevData => (
      {
        ...prevData,
        checks: {
          ...prevData.checks,
          [name]: checked
        }
      }));
  }

  return {
    handleChange,
    handleCheckChange,
    setValidation,
    setData,
    validation,
    data,
    isNeedState,
    countries,
    states,
  }
}
