import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss';

const tKey = 'landing.great_insights';

export const GreatInsights = () => {

  const options = t(`${tKey}.options`, { returnObjects: true }) as unknown as string[];

  return <div className={classes.wrapper}>
    <div className={classes.title}>
      {t(`${tKey}.title`)}
    </div>
    <div className={classes.options}>
      {options.map((option, index) => {
        return <div key={index} className={classes.option}>
          {option}
        </div>
      })}
    </div>
  </div>
}

export default GreatInsights;