import { createContext, useContext } from 'react';

export interface PersonalizationPopupContext {
  onOpen: () => void;
}

const personalizationPopupContext = createContext({} as PersonalizationPopupContext);
export const PersonalizationPopupContextProvider = personalizationPopupContext.Provider

export const usePersonalizationPopupContext = () => {
  const context = useContext(personalizationPopupContext);
  return context;
}