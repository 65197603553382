import { type FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Provider } from '@web-solutions/react-billing';

import {
  selectLoading,
  selectPaymentSystem,
  type ProductDetails,
} from '@web-solutions/core/store/billing/selectors';

import ThreeDSecure from '@web-solutions/core/payment/components/threeDSecure';
import { Preloader } from '@web-solutions/core/ui-elements';

import {
  useGooglePayProcessor,
  useApplePayProcessor,
  usePayPalProcessor,
  usePaymentProcessorBase
} from '@web-solutions/core/payment/hooks';

import { getIsRenderAvailable as getIsApplePayButtonRenderAvailable } from '@web-solutions/core/payment/components/apple-pay/utils';

import type { OrderDetails, SolidMetadata, Subscription } from '@web-solutions/core/interfaces/billing';

import { INPUT_STYLES, LABEL_STYLES, SUBMIT_STYLES } from './constants/payment-card-styles'

import { PaymentMethods } from './components'

import { RenderConfig } from './types';

export interface PaymentProcessorProps {
  activeProduct: ProductDetails,
  orderDetails: OrderDetails | null,
  onSuccess: (purchase: Subscription) => void,
  trigger?: string,
  orderMetadata?: any;
  orderSolidMetadata?: SolidMetadata;
  onError?: (error: any) => void,
  onSubmit?: () => void;
}

export const PaymentProcessor: FC<PaymentProcessorProps> = ({
  activeProduct,
  orderDetails,
  trigger,
  orderMetadata,
  orderSolidMetadata,
  onSuccess,
  onError,
  onSubmit
}) => {
  const paymentSystem = useSelector(selectPaymentSystem);

  const isPending = useSelector((state: any) => state.billing.pending || state.billing.orderPending);
  const isLoadingBilling = useSelector(selectLoading);
  const isLoadingApp = useSelector((state: any) => !state.app.loaded);

  const {
    formOptions,
    tokenThreeDSecure,
    processFormData,
    handleSubmit,
    handlePaymentSuccess,
    handleCardFormSubmit,
    handleCardPaymentSuccess,
    handleCardPaymentError,
    handle3DTokenExchanged,
    handle3DTokenError,
    handleUserInfoChange
  } = usePaymentProcessorBase({
    analytics: 'section_payment',
    activeProduct,
    paymentSystem,
    orderDetails,
    onSuccess,
    onError,
    onSubmit,
  })

  const {
    showApplePay,
    handleApplePayClick,
    handleApplePaySubmit
  } = useApplePayProcessor({
    paymentSystem,
    trigger,
    orderMetadata,
    orderSolidMetadata,
    handleCardPaymentError,
    handlePaymentSuccess,
    handleSubmit
  });

  const {
    showGooglePay,
    handleGooglePayClick,
    handleGooglePaySubmit
  } = useGooglePayProcessor({ handleSubmit });

  const {
    showPayPal,
    handlePayPalSubmit
  } = usePayPalProcessor({
    activeProduct,
    onSubmit: processFormData
  });

  const renderConfig: RenderConfig = useMemo(() => ([
    {
      name: 'apple-pay',
      condition: true,
      props: {
        containerId: formOptions?.applePayButtonParams?.containerId,
        product: activeProduct,
        isButtonVisible: showApplePay,
        isContainerVisible: showApplePay && !getIsApplePayButtonRenderAvailable(paymentSystem),
        handleApplePayClick,
        handleApplePaySubmit
      }
    },
    {
      name: 'google-pay',
      condition: true,
      props: {
        containerId: formOptions?.googlePayButtonParams?.containerId,
        isContainerVisible: showGooglePay
      }
    },
    {
      name: 'pay-pal',
      condition: showPayPal,
      props: {
        product: activeProduct,
        handlePayPalSubmit,
        handlePayPalSuccess: handleCardPaymentSuccess,
        handlePayPalError: handleCardPaymentError
      }
    },
    {
      name: 'base-form',
      condition: true,
      props: {
        product: activeProduct,
        options: formOptions,
        inputStyles: INPUT_STYLES,
        labelStyles: LABEL_STYLES,
        notEmptyLabelStyles: LABEL_STYLES,
        submitStyles: SUBMIT_STYLES,
        paymentSystem,
        formClassName: 'payment-form',
        fieldClassName: 'form-field',
        onSubmit: handleCardFormSubmit,
        onSuccess: handleCardPaymentSuccess,
        onError: handleCardPaymentError,
        onApplePayClick: handleApplePayClick,
        onApplePaySubmit: handleApplePaySubmit,
        onGooglePayClick: handleGooglePayClick,
        onGooglePaySubmit: handleGooglePaySubmit,
        onUserInfoChange: handleUserInfoChange,
      }
    }
  ]), [
    activeProduct,
    paymentSystem,
    formOptions,
    showApplePay,
    showGooglePay,
    showPayPal,
    handleCardFormSubmit,
    handleCardPaymentSuccess,
    handleCardPaymentError,
    handleUserInfoChange,
    handleApplePayClick,
    handleApplePaySubmit,
    handleGooglePayClick,
    handleGooglePaySubmit,
    handlePayPalSubmit
  ])

  return (
    <>
      {isPending && (
        <Preloader className='PaymentModalPreloader' />
      )}
      {
        (isLoadingApp || isLoadingBilling) ?
          <Preloader className='PaymentModalPreloader' /> :
          <Provider>
            <PaymentMethods renderConfig={renderConfig} />
          </Provider>
      }
      {
        tokenThreeDSecure && (
          <ThreeDSecure
            actionTokenId={tokenThreeDSecure}
            onToken={handle3DTokenExchanged}
            onError={handle3DTokenError}
          />
        )
      }
    </>
  )
}