import { type FC, useMemo } from 'react'
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization'

import { baseTKey } from '../../constants'

import { BADGE_ICONS_LOOKUP } from './constants'

import type { OrderStatusItem } from './types'

import classes from './style.module.scss'

const tKey = `${baseTKey}.main.status`

export const OrderStatus: FC = () => {
  const ORDER_STATUS_LIST: OrderStatusItem[] = useMemo(() => (
    [
      { text: t(`${tKey}.order_placed`), status: 'success' },
      { text: t(`${tKey}.preparing`), status: 'inProgress' },
      { text: t(`${tKey}.printing`), status: 'waiting' },
      { text: t(`${tKey}.shipped`), status: 'waiting' },
    ]
  ), [])

  return (
    <ul className={classes.orderStatusWrapper}>
      {ORDER_STATUS_LIST.map(({ text, status }, index) => {
        const Icon = BADGE_ICONS_LOOKUP[status]

        return (
          <li key={index} className={classes.item}>
            <div className={classNames(classes.badge, classes[status])}>
              {Icon && <Icon />}
            </div>
            <p className={classNames(classes.text, classes[status])}>
              {text}{status === 'inProgress' && '...'}
            </p>
          </li>
        )
      })}
    </ul >
  )
}
