import { t } from '@web-solutions/module-localization';

import { Image } from 'core/ui-elements';

import { LandingButton } from 'src/components/landing-button';

import classes from './style.module.scss';
import { IMAGES } from './images';

const tKey = 'landing.birth_chart';

export const BirthChart = () => {
  return <div className={classes.wrapper}>
    <div className={classes.header}>
      <div className={classes.title}>
        {t(`${tKey}.title`)}
      </div>
      <div className={classes.text}>
        {t(`${tKey}.subtitle`)}
      </div>
    </div>
    <Image img={IMAGES.BOOK} alt='birth-chart' />
    <div className={classes.footer}>
      <div className={classes.text}>
        {t(`${tKey}.text`)}
      </div>
      <LandingButton className={classes.button} analyticCategory='landing_birth_chart' />
    </div>
  </div>
}

export default BirthChart;