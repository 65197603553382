import type { FC, ComponentProps } from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

export interface ButtonProps extends ComponentProps<'button'> { }

const Button: FC<ButtonProps> = ({
  id,
  onClick,
  children,
  disabled = false,
  className = null,
  type = 'button',
  ...props
}) => (
  <button
    id={id}
    type={type}
    onClick={onClick}
    className={classNames(classes.button, className)}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

export default Button;
