import type { FC } from 'react'

import classes from './styles.module.scss';

interface BaseInitialProps {
  text: string;
}

export const BaseInitial: FC<BaseInitialProps> = ({ text }) => (
  <p className={classes.text}>{text}</p>
)