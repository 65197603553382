import React from "react"

import { t } from "@web-solutions/module-localization"

import { LandingButton } from "src/components/landing-button"

import classes from './style.module.scss'

const tKey = 'landing.what_inside'

export const WhatInside: React.FC = () => {

  const options = t(`${tKey}.options`, { returnObjects: true }) as unknown as Array<Record<string, string>>

  return <div className={classes.wrapper}>
    <div className={classes.title}>{t(`${tKey}.title`)}</div>
    <div className={classes.options_wrapper}>
      {options.map(({ title, text, index }) => {
        return <div key={index} className={classes.option}>
          <div className={classes.option_number}>{index}</div>
          <div className={classes.option_title}>{title}</div>
          <div className={classes.option_text}>
            {text}
          </div>
        </div>
      })}
    </div>
    <LandingButton analyticCategory='landing_what_inside' />
  </div>
}

export default WhatInside;