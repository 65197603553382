import type { FC, ComponentProps } from 'react'
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization'

import { Image, Title } from '@web-solutions/core/ui-elements'

import { IMAGES } from './images'

import { useGetProcessedInfo } from './hooks'

import classes from './style.module.scss'

const tKey = 'products.books.birth_chart'

export const ProductAndCustomerInfo: FC<ComponentProps<'div'>> = ({ className, children, ...props }) => {
  const { fullName, birthDateFullInfo, location } = useGetProcessedInfo();

  return (
    <div className={classNames(classes.productAndCustomerWrapper, className)} {...props}>
      <div className={classes.headerWrapper}>
        <div className={classes.productWrapper}>
          <Image
            className={classes.image}
            img={IMAGES.BIRTH_CHART_HARDCOVER}
            alt='birth chart hardcover'
          />
          <div>
            <Title level='h4' className={classes.title}>
              {t(`${tKey}.name`)}
            </Title>
            <p className={classes.text}>
              {t(`${tKey}.description`)}
            </p>
          </div>
        </div>
        {children}
      </div>
      <div>
        <Title level='h4' className={classNames(classes.title, classes.name)}>
          {fullName}
        </Title>
        <p className={classes.text}>
          {birthDateFullInfo}
        </p>
        <p className={classes.text}>
          {location?.label}
        </p>
      </div>
    </div>
  )
}
